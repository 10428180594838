import styles from './Title.module.scss';

type PropsType = {
  title: string;
};

const Title = ({ title }: PropsType) => {
  return <h1 className={styles.title}>{title}</h1>;
};

export default Title;
