import styles from './MainContainer.module.css';

type PropsType = {
  children: any;
};

const MainContainer = ({ children }: PropsType) => {
  return <div className={styles.container}>{children}</div>;
};

export default MainContainer;
