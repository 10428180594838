export class TrailerPreview {
  public Name: string = '';
  public Image: string = '';
  public Price: number = 0.0;
  public Tags: string[] = [];

  public constructor(init?: Partial<TrailerPreview>) {
    Object.assign(this, init);
  }
}
