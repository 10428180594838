import logo from '../../assets/logo.webp';
import Logo from '../Logo/Logo';
import styles from './Footer.module.scss';

type PropsType = {};

//eslint-disable-next-line
const Footer = ({}: PropsType) => {
  return (
    <div className={styles.container}>
      <div className={styles.columnContainer}>
        <div className={styles.logoContainer}>
          <Logo logo={logo} />
        </div>
        <p>© Copyright {new Date().getFullYear()} Anhänger Balu</p>
      </div>
    </div>
  );
};

export default Footer;
