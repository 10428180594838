import { AiOutlineClockCircle, AiOutlineEnvironment, AiOutlineInfoCircle, AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { EventService, EventType } from '../../services/EventService';
import ContainerTitle from '../ContainerTitle/ContainerTitle';
import styles from './Contact.module.scss';

type PropsType = {};

//eslint-disable-next-line
const Contact = ({}: PropsType) => {
  const handlePhoneButton = () => {
    EventService.CreateEventAsync(EventType.FooterPhone);
  };

  const handleEmailButton = () => {
    EventService.CreateEventAsync(EventType.FooterEmail);
  };

  return (
    <div className={styles.container}>
      <ContainerTitle title={'Kontakt'} />
      <div className={styles.rowContainer}>
        <div className={styles.columnContainer}>
          <p className={styles.title}>Öffnungszeiten</p>
          <p>
            <AiOutlineClockCircle />
            Mo - So: 8 - 21 Uhr
          </p>
          <p>
            <AiOutlineInfoCircle />
            Termine nach telefonischer Absprache
          </p>
        </div>

        <div className={styles.columnContainer}>
          <p className={styles.title}>Adresse</p>
          <p>
            <AiOutlineEnvironment />
            <a href={'https://goo.gl/maps/hRe7ja69ZxtqFyHi8'}>
              Anhänger Balu
              <br />
              Deidesheimer Straße
              <br />
              70499 Stuttgart
            </a>
          </p>
          <p>
            <AiOutlinePhone />
            <a href={'tel:+4917660278683'} onClick={handlePhoneButton}>
              {' '}
              +49 17660278683
            </a>
          </p>
          <p>
            <AiOutlineMail />
            <a href={'mailto:info@anhaenger-balu.de'} onClick={handleEmailButton}>
              {' '}
              info@anhaenger-balu.de
            </a>
          </p>
          <p>
            <AiOutlineInfoCircle />
            <a href={'/impressum'}> Impressum & Datenschutz</a>
          </p>
        </div>

        <div className={styles.columnContainer}>
          <p className={styles.title}>Anfahrt</p>
          <iframe
            title={'anfahrt'}
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2627.451642887673!2d9.116421316255797!3d48.81144351196409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799da4873b39f41%3A0x3873a910e665be8b!2sAnh%C3%A4nger%20Balu!5e0!3m2!1sde!2sde!4v1635675700724!5m2!1sde!2sde'
            width='auto'
            height='auto'
            style={{ border: 0 }}
            allowFullScreen={false}
            loading='lazy'
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
