import easyImg from '../../assets/Handshake_Two Color.webp';
import cheapImg from '../../assets/Money jar_Two Color.webp';
import flexibleImg from '../../assets/Super Man_Two Color.webp';
import ContainerTitle from '../ContainerTitle/ContainerTitle';
import Service from './Service/Service';
import styles from './Services.module.scss';

type PropsType = {};

//eslint-disable-next-line
const Services = ({}: PropsType) => {
  return (
    <div className={styles.container}>
      <ContainerTitle title={'Unser Service'} />
      <p className={styles.description}>
        Für Umzüge, Gartenarbeiten und den Transport anderer großer Gegenstände ist der eigene Wagen meist zu klein. In solchen aber auch anderen Fällen ist der Anhänger die ideale
        Lösung. Den gewünschten PKW-Anhänger können sie bei uns zu günstigen Konditionen mieten. Wir sind ein kleiner, auf die Vermietung von Anhängern spezialisierter Betrieb im
        Stuttgarter Norden. Unsere Stärke ist unsere Flexibilität und die unkomplizierte Gestaltung.{' '}
      </p>
      <div className={styles.servicesContainer}>
        <Service img={easyImg} title={'Einfach'} description={'In nur wenigen Schritten, kriegen Sie bei uns, Ihren gewünschten Anhänger.'} />
        <Service img={flexibleImg} title={'Flexibel'} description={'In dringenden Fällen können auch außerhalb der Geschäftszeiten Termine vereinbart werden. '} />
        <Service img={cheapImg} title={'Preisgünstig'} description={'Ausgezeichnete Qualität für einen unschlagbaren Preis. '} />
      </div>
    </div>
  );
};

export default Services;
