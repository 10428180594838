import styles from './Chip.module.scss';

type PropsType = {
  text: string;
};

const Chip = ({ text }: PropsType) => {
  return (
    <div className={styles.container}>
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default Chip;
