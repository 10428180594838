import { Constants } from '../constants/Constants';
import { useMediaQuery } from '../hooks/useMediaQuery';

type PropsType = {
  children: any;
};

const DesktopContainer = ({ children }: PropsType) => {
  const isDesktop = useMediaQuery({ query: Constants.MEDIA_DESKTOP });

  if (isDesktop) return children;

  return null;
};

export default DesktopContainer;
