import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import styles from './App.module.scss';
import CookieBanner from './components/CookieBanner/CookieBanner';
import ImpressumPage from './pages/ImpressumPage';
import MainPage from './pages/MainPage';
import { EventService } from './services/EventService';
import { Log } from './services/Log';

const App = () => {
  useEffect(() => {
    Log.InitLogging();

    EventService.CreatePageVisitEventAsync();
  }, []);

  return (
    <div className={styles.App}>
      <Router>
        <Routes>
          <Route path='/' element={<MainPage />} />
          <Route path='/impressum' element={<ImpressumPage />} />
          {/* <Route path='/anhaenger' element={<TrailerPage />} /> */}
        </Routes>
      </Router>
      <CookieBanner />
    </div>
  );
};

export default App;
