import { useState } from 'react';
import { CookieService } from '../../services/CookieService';
import styles from './CookieBanner.module.scss';

type PropsType = {};

const AcceptCookiesKey = 'acceptCookies';

//eslint-disable-next-line
const CookieBanner = ({}: PropsType) => {
  const [cookiesAccepted, setCookiesAccepted] = useState(CookieService.GetCookie(AcceptCookiesKey) !== '');

  const onClick = () => {
    CookieService.SetCookie(AcceptCookiesKey, true, 365);
    setCookiesAccepted(true);
  };

  const dynamicStyles = [styles.container];

  if (!cookiesAccepted) {
    dynamicStyles.push(styles.containerShow);
  }

  return (
    <div className={dynamicStyles.join(' ')}>
      Diese Website benutzt Cookies. Wenn Sie die Website weiter nutzen, gehen wir von Ihrem Einverständnis aus.
      <button type='button' className={styles.button} onClick={onClick}>
        OK
      </button>
    </div>
  );
};

export default CookieBanner;
