import { RxDot, RxDotFilled } from 'react-icons/rx';
import styles from './CarouselProgress.module.scss';

type PropsType = {
  currentIndex: number;
  numberOfItems: number;
};

const CarouselProgress = ({ currentIndex, numberOfItems }: PropsType) => {
  const getItems = () => {
    const items = [];

    for (let i = 0; i < numberOfItems; ++i) {
      if (i === currentIndex) {
        items.push(<RxDotFilled key={i} size={20} />);
        continue;
      }

      items.push(<RxDot key={i} size={20} />);
    }

    return items;
  };

  return <div className={styles.container}>{getItems()}</div>;
};

export default CarouselProgress;
