import { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { TrailerPreview } from '../../models/TrailerPreview';
import Chip from '../Chip/Chip';
import styles from './Carousel.module.scss';
import CarouselProgress from './CarouselProgress/CarouselProgress';

type PropsType = {
  trailers: TrailerPreview[];
};

const Carousel = ({ trailers }: PropsType) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const increaseCurrentIndex = () => {
    if (currentIndex === trailers.length - 1) {
      setCurrentIndex(0);
      return;
    }

    setCurrentIndex(currentIndex + 1);
  };

  const decreaseCurrentIndex = () => {
    if (currentIndex === 0) {
      setCurrentIndex(trailers.length - 1);
      return;
    }

    setCurrentIndex(currentIndex - 1);
  };

  const getImages = () => {
    return trailers.map((val, idx) => {
      return (
        <div key={idx} className={[styles.imageContainer, currentIndex === idx ? styles.activeImageContainer : ''].join(' ')}>
          <img className={styles.image} src={val.Image} alt={val.Name} />
          <div className={styles.trailerDataContainer}>
            <p className={styles.title}>{val.Name}</p>
            <span className={styles.price}>ab {val.Price} €</span>
            <div className={styles.rowContainer}>
              {val.Tags.map((v, i) => (
                <Chip key={i} text={v} />
              ))}
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonContainerLeft} onClick={decreaseCurrentIndex}>
        <FiChevronLeft size={20} />
      </div>
      <div className={styles.carouselContainer}>{getImages()}</div>
      <div className={styles.buttonContainerRight} onClick={increaseCurrentIndex}>
        <FiChevronRight size={20} />
      </div>

      <CarouselProgress currentIndex={currentIndex} numberOfItems={trailers.length} />
    </div>
  );
};

export default Carousel;
