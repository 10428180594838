import { FiMenu } from 'react-icons/fi';
import MobileContainer from '../../../containers/MobileContainer';
import styles from './DrawerToggle.module.scss';

type PropsType = {
  onToggleDrawer: () => void;
};

const DrawerToggle = ({ onToggleDrawer }: PropsType) => {
  return (
    <MobileContainer>
      <div className={styles.container} onClick={onToggleDrawer}>
        <FiMenu className={styles.icon} />
      </div>
    </MobileContainer>
  );
};

export default DrawerToggle;
