import { Fragment } from 'react';
import Backdrop from '../../components/Backdrop/Backdrop';
import DesktopContainer from '../../containers/DesktopContainer';
import MobileContainer from '../../containers/MobileContainer';
import { EventService, EventType } from '../../services/EventService';
import DrawerToggle from './DrawerToggle/DrawerToggle';
import styles from './Navbar.module.scss';
import NavbarLogo from './NavbarLogo/NavbarLogo';

type PropsType = {
  onToggleDrawer: () => void;
  showDrawer: boolean;
};

const Navbar = ({ onToggleDrawer, showDrawer }: PropsType) => {
  const handlePhoneButton = () => {
    EventService.CreateEventAsync(EventType.NavbarPhone);
  };

  const getNavItems = () => {
    return (
      <Fragment>
        <li className={styles.navItem}>
          <a className={styles.navLink} href={'/#service'} onClick={onToggleDrawer}>
            Unser Service
          </a>
        </li>
        <li className={styles.navItem}>
          <a className={styles.navLink} href={'/#anhaenger'} onClick={onToggleDrawer}>
            Unsere Anhänger
          </a>
        </li>
        <li className={styles.navItem}>
          <a className={styles.navLink} href={'/#contact'} onClick={onToggleDrawer}>
            Kontakt
          </a>
        </li>
        <DesktopContainer>
          <li className={styles.navItem}>
            <a className={styles.navActionButton} href={'tel:+4917660278683'} onClick={handlePhoneButton}>
              Jetzt anrufen!
            </a>
          </li>
        </DesktopContainer>
      </Fragment>
    );
  };

  const drawerContainerStyles = [styles.drawerContainer];
  if (showDrawer) drawerContainerStyles.push(styles.drawerContainerShow);

  return (
    <header className={styles.navbar}>
      <div className={styles.navbarContainer}>
        <NavbarLogo />
        <MobileContainer>
          <a className={styles.navActionButton} href={'tel:+4917660278683'} onClick={handlePhoneButton}>
            Jetzt anrufen!
          </a>
          <DrawerToggle onToggleDrawer={onToggleDrawer} />
        </MobileContainer>
        <DesktopContainer>
          <ul className={[styles.navItemsContainer, styles.desktop].join(' ')}> {getNavItems()}</ul>
        </DesktopContainer>
      </div>
      <MobileContainer>
        <div className={drawerContainerStyles.join(' ')}>
          <ul className={[styles.navItemsContainer, styles.mobile].join(' ')}>{getNavItems()}</ul>
          <Backdrop show={showDrawer} onClick={onToggleDrawer} />
        </div>
      </MobileContainer>
    </header>
  );
};

export default Navbar;
