import styles from './Container.module.scss';

type PropsType = {
  id?: string;
  children: any;
};

const Container = ({ id, children }: PropsType) => {
  return (
    <div id={id} className={styles.container}>
      {children}
    </div>
  );
};

export default Container;
