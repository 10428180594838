import logo from '../../../assets/logo.webp';
import Logo from '../../../components/Logo/Logo';
import styles from './NavbarLogo.module.css';

const NavbarLogo = () => {
  return (
    <div className={styles.container}>
      <a href='/'>
        <Logo logo={logo} />
      </a>
    </div>
  );
};

export default NavbarLogo;
