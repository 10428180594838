import styles from './Logo.module.css';

type PropsType = {
  logo: string;
};

const Logo = ({ logo }: PropsType) => {
  return (
    <div className={styles.logo}>
      <img className={styles.image} src={logo} alt='Brand Logo' />
    </div>
  );
};

export default Logo;
