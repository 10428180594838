import { Fragment, ReactNode, useState } from 'react';
import MainContainer from '../containers/MainContainer';
import Navbar from './Navbar/Navbar';
type PropsType = {
  children: ReactNode;
};

const Layout = ({ children }: PropsType) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <Fragment>
      <Navbar onToggleDrawer={toggleDrawer} showDrawer={showDrawer} />
      <MainContainer>{children}</MainContainer>
    </Fragment>
  );
};

export default Layout;
