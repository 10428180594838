import React from 'react';
import styles from './SubTitle.module.scss';

type PropsType = {
  title: string;
};

const SubTitle = ({ title }: PropsType) => {
  return <h3 className={styles.title}>{title}</h3>;
};

export default SubTitle;
