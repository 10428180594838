import { Log } from './Log';

export class HttpClient {
  public static async PostAsync(uri: string, content: any) {
    try {
      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(content)
      });
      return response;
    } catch (error) {
      Log.Error(`Failed to send POST request. '${uri}': ''${JSON.stringify(content)}`);
    }
  }

  public static async GetAsync(uri: string, query?: string) {
    const requestUri = uri + (query !== undefined ? `?${query}` : '');
    try {
      const response = await fetch(requestUri, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return response;
    } catch (error) {
      Log.Error(`Failed to send GET request. '${requestUri}'`);
    }
  }
}
