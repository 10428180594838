import autoImg from '../../assets/Autotransporter.webp';
import gitterImg from '../../assets/Gitter.webp';
import motorradImg from '../../assets/Motorradtransporter.webp';
import kastenImg from '../../assets/kastenanhaenger_hoch.webp';
import { TrailerPreview } from '../../models/TrailerPreview';
import Carousel from '../Carousel/Carousel';
import ContainerTitle from '../ContainerTitle/ContainerTitle';
import styles from './Trailers.module.scss';

const trailers = [
  new TrailerPreview({ Name: 'Kastenanhänger mit Plane', Image: kastenImg, Price: 25, Tags: ['300Lx150Bx140H', '100km/h', 'gebremst', '1300kg'] }),
  new TrailerPreview({ Name: 'Autotransporter', Image: autoImg, Price: 40, Tags: ['400Lx200B', '100km/h', 'gebremst', '2700kg'] }),
  new TrailerPreview({ Name: 'Motorradtransporter', Image: motorradImg, Price: 25, Tags: ['100km/h', 'gebremst', '750kg'] }),
  new TrailerPreview({ Name: 'Kastenanhänger mit Gitteraufsatz', Image: gitterImg, Price: 30, Tags: ['250Lx131Bx90H', 'gebremst', '1300kg'] })
];

type PropsType = {};

//eslint-disable-next-line
const Trailers = ({}: PropsType) => {
  return (
    <div className={styles.container}>
      <ContainerTitle title={'Unsere Anhänger'} />
      <Carousel trailers={trailers} />
      {/* <Accordion itemList={trailerList} /> */}
    </div>
  );
};

export default Trailers;
