import { useMediaQuery } from '../hooks/useMediaQuery';
import { Constants } from '../constants/Constants';

type PropsType = {
  children: any;
};

const MobileContainer = ({ children }: PropsType) => {
  const isMobile = useMediaQuery({ query: Constants.MEDIA_MOBILE });

  if (isMobile) return children;

  return null;
};

export default MobileContainer;
