import { Fragment } from 'react';
import whatsAppLogo from '../../assets/whatsapp.svg';
import { EventService, EventType } from '../../services/EventService';
import styles from './WhatsAppButton.module.scss';

type PropsType = {};

//eslint-disable-next-line
const WhatsAppButton = ({}: PropsType) => {
  const handleClick = () => {
    EventService.CreateEventAsync(EventType.WhatsApp);
  };

  return (
    <Fragment>
      <div className={styles.container}>
        <a href='https://api.whatsapp.com/send?phone=4917660278683' onClick={handleClick}>
          <img className={styles.image} src={whatsAppLogo} alt='WhatsApp' />
        </a>
      </div>

      <div className={styles.shadowBox} />
    </Fragment>
  );
};

export default WhatsAppButton;
