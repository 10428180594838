import React from 'react';
import styles from './Section.module.scss';

type PropsType = {
  id: string;
  bgColor?: string;
  children: any;
};

const Section = ({ id, bgColor, children }: PropsType) => {
  return (
    <section id={id} className={styles.container} style={{ backgroundColor: bgColor }}>
      {children}
    </section>
  );
};

export default Section;
