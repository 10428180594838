import styles from './LinkButton.module.scss';

type PropsType = {
  text: string;
  link: string;
  icon?: any;
  primary?: boolean;
  onClick: () => void;
};

const LinkButton = ({ text, link, icon, primary, onClick }: PropsType) => {
  const buttonStyles = [styles.button, primary ? styles.primary : styles.secondary];

  return (
    <button className={buttonStyles.join(' ')} onClick={onClick}>
      <a className={styles.link} href={link}>
        <div className={styles.buttonContainer}>
          {text}
          <div className={styles.iconContainer}>{icon}</div>
        </div>
      </a>
    </button>
  );
};

export default LinkButton;
