import React from 'react';
import styles from './Backdrop.module.scss';

type PropsType = {
  show: boolean;
  onClick: () => void;
};

const Backdrop = ({ show, onClick }: PropsType) => {
  if (!show) return null;

  return <div onClick={onClick} className={styles.backdrop} />;
};

export default Backdrop;
