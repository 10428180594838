import { EnvironmentHelper } from './EnvironmentHelper';

export class Log {
  public static InitLogging() {
    this.Debug('Logging is enabled.');
  }

  public static Debug(message: string) {
    this.WriteInternal('DBG', message);
  }

  public static Error(message: string) {
    this.WriteInternal('ERR', message);
  }

  private static WriteInternal(logLevel: string, message: string) {
    if (!EnvironmentHelper.IsDebug()) return;

    console.log(`[${logLevel}] ${message}`);
  }
}
