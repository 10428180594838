import { CookieService } from './CookieService';
import { HttpClient } from './HttpClient';
import { Log } from './Log';

export enum EventType {
  None = 'None',
  PageVisit = 'PageVisit',
  NavbarPhone = 'NavbarPhone',
  HeaderPhone = 'HeaderPhone',
  HeaderEmail = 'HeaderEmail',
  FooterPhone = 'FooterPhone',
  FooterEmail = 'FooterEmail',
  WhatsApp = 'WhatsApp'
}

export class EventService {
  private static LastVisitKey = 'lastVisit';

  private static IsInPast(date: Date) {
    let currentDate = new Date();

    if (date.getUTCFullYear() < currentDate.getUTCFullYear()) return true;
    if (date.getUTCMonth() < currentDate.getUTCMonth()) return true;
    if (date.getUTCDate() < currentDate.getUTCDate()) return true;

    return false;
  }

  private static CanCreatePageEvent() {
    let lastVisitStr = CookieService.GetCookie(this.LastVisitKey);
    if (!lastVisitStr) {
      return true;
    }

    let lastVisit = new Date(lastVisitStr);

    return this.IsInPast(lastVisit);
  }

  public static async CreatePageVisitEventAsync() {
    if (!this.CanCreatePageEvent()) return;

    await this.CreateEventAsync(EventType.PageVisit);

    let currentDate = new Date();

    CookieService.SetCookie(this.LastVisitKey, currentDate.toUTCString(), 1);
  }

  public static async CreateEventAsync(eventType: EventType) {
    try {
      let data = {
        date: new Date(),
        platform: navigator.platform,
        userAgent: navigator.userAgent,
        language: navigator.language,
        online: navigator.onLine,
        referrer: document.referrer,
        type: eventType
      };

      await HttpClient.PostAsync(`${window.location.origin}/.netlify/functions/mut`, data);

      Log.Debug(`Event '${eventType}' created.`);
    } catch {
      Log.Error(`Failed to create event '${eventType}'.`);
    }
  }
}
